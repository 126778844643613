<template>
  <v-row no-gutters justify="center" align="center" class="mt-4">
    <v-card width="100%"
      ><v-container>
        <v-form
          ref="createQuestion"
          v-model="checkValidate"
          :lazy-validation="lazy"
          ><h2 class="headtitle">สร้างคำถาม</h2>
          <v-row no-gutters justify="center" align="center" class="mt-5">
            <!-- <v-row> -->
            <v-col cols="12" md="12" sm="12" xs="12"
              >ชื่อคำถาม<v-col cols="12" md="6" sm="12" xs="12"
                ><v-text-field
                  :rules="rules.required"
                  outlined
                  dense
                  v-model="topic"
                  placeholder="คำถาม"
                ></v-text-field></v-col
            ></v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              ชนิดคำถาม
              <v-col cols="12" md="6" sm="12" xs="12"
                ><v-select
                  outlined
                  dense
                  v-model="type"
                  :items="items"
                ></v-select
              ></v-col>
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="12"
              xs="12"
              v-if="type == 'DROPDOWN' || type == 'CHECKBOX' || type == 'RADIO'"
            >
              <v-row v-for="(n, index) in choices" :key="index" no-gutters>
                <v-col cols="12" md="12" sm="12" xs="12" class="ma-0 pa-0">
                  <v-col cols="12" md="6" sm="12" xs="12">
                    <v-text-field
                      :rules="rules.required"
                      outlined
                      dense
                      v-model="n.choiceName"
                      placeholder="ตัวเลือก"
                    ></v-text-field>
                  </v-col>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" md="6" sm="12" xs="12">
                  <v-btn
                    @click="popChoice()"
                    rounded
                    block
                    dense
                    color="white"
                    style="color: black"
                    ><v-icon>mdi-plus-circle</v-icon>ลบตัวเลือก</v-btn
                  >
                </v-col>
                <v-col cols="6" md="6" sm="12" xs="12">
                  <v-btn
                    @click="addChoice()"
                    rounded
                    block
                    dense
                    color="#833133"
                    style="color: white"
                    ><v-icon>mdi-plus-circle</v-icon>เพิ่มตัวเลือก</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>

            <!-- <v-col cols="12"
              >รายละเอียดเพิ่มเติม<v-col cols="6"
                ><v-text-field
                  :rules="rules.required"
                  outlined
                  dense
                  v-model="description"
                  placeholder="รายละเอียดเพิ่มเติม"
                ></v-text-field></v-col
            ></v-col> -->
            <!-- <v-col cols="12"
              ><v-switch v-model="switch1" label="ใช้งาน"></v-switch
            ></v-col> -->
            <v-col class="mt-4"
              ><v-btn @click="cancel()">ยกเลิก</v-btn>
              <v-btn
                class="ml-12"
                color="#833133"
                style="color: white"
                @click="submit()"
                >บันทึก</v-btn
              ></v-col
            >
          </v-row></v-form
        ></v-container
      >
    </v-card>
  </v-row>
</template>
<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      checkValidate: true,
      lazy: false,
      switch1: true,
      formId: "",
      topic: "",
      type: "",
      items: [
        { text: "เลือก", value: "" },
        { text: "ให้คะแนน", value: "C" },
        { text: "ช่องคำตอบ", value: "P" },
        // { text: "Drop Down", value: "DROPDOWN" },
        { text: "Check Box", value: "CHECKBOX" },
        { text: "Radio", value: "RADIO" },
      ],
      formName: "",
      description: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน"],
        password: [(v) => !!v || "Password is required"],
        image: [(v) => !!v || "Image is required"],
        name: [(v) => !!v || "Name is required"],
        sername: [(v) => !!v || "Sername is required"],
        duty: [(v) => !!v || "Duty is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        selectRules: [(val) => !!val || "กรุณากรอกข้อมูล"],
      },
      choices: [],
    };
  },
  created() {
    var Questiondata = JSON.parse(
      Decode.decode(localStorage.getItem("Questions"))
    );
    console.log("Questiondata", Questiondata);
    this.formId = Questiondata.id;
  },
  methods: {
    async submit() {
      if (this.$refs.createQuestion.validate(true)) {
        for (let i = 0; i < this.choices.length; i++) {
          this.choices[i].type = this.type;
        }
        const data = {
          formId: this.formId,
          topic: this.topic,
          type: this.type,
          choices: this.choices,
        };
        console.log("data", data);
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/form/createQuestion`,
          data
        );
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            title: `สร้างคำถามสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageQuestions");
        } else {
          this.$swal.fire({
            icon: "error",
            title: `สร้างคำถามไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    addChoice() {
      this.choices.push({
        choiceName: "",
      });
    },
    popChoice() {
      this.choices.pop({
        choiceName: "",
      });
    },
    cancel() {
      (this.formName = ""), (this.description = ""), this.resetValidation();
      this.$router.push("ManageQuestions");
    },
    resetValidation() {
      this.$refs.createQuestion.resetValidation();
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>
